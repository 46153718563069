
.cursor {
    cursor: pointer;
}

.table-checkbox-w {
    width: 1.25rem;
}

.avatar-margin {
    padding-right: .5rem;
}

.table-full-height {
    overflow-y: auto;
    max-height: calc(100vh - 275px);
}

.table-full-height::-webkit-scrollbar {
    width: .4rem;
    height: .4rem;
}

.table-full-height::-webkit-scrollbar-track {
    border-radius: 3px;
}

.table-full-height::-webkit-scrollbar-thumb {
    border-radius: .8rem;
    background: var(--falcon-gray-400);
}

.title-case {
    text-transform: capitalize;
}

.capitalize:first-letter {
    text-transform: capitalize;
}

.width-100px {
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
}


@media only screen and (min-width: 576px) {
    .width-100px {
        min-width: 100px !important;
    }
}